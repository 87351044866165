<template>
  <v-layout v-if="_.size(detail) > 0" row wrap>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Composición" subtitle="Insumos que lo componen"> </titleCard>
        <v-divider></v-divider>
        <v-data-table
          :items="rows"
          class="elevation-0"
          no-data-text="No hay insumos ingresados"
          :headers="
            user.role.id !== 7
              ? [
                  { text: '', align: 'left', sortable: false },
                  { text: 'Insumo', align: 'left', sortable: false },
                  { text: 'Cantidad', align: 'left', sortable: false },
                  { text: 'Precio', align: 'left', sortable: false },
                  { text: 'Atributo', align: 'left', sortable: false },
                  { text: 'Web', align: 'left', sortable: false },
                  { text: '', align: 'left', sortable: false }
                ]
              : [
                  { text: '', align: 'left', sortable: false },
                  { text: 'Insumo', align: 'left', sortable: false },
                  { text: 'Cantidad', align: 'left', sortable: false },
                  { text: 'Atributo', align: 'left', sortable: false },
                  { text: 'Web', align: 'left', sortable: false },
                  { text: '', align: 'left', sortable: false }
                ]
          "
          hide-actions
        >
          <template v-slot:items="props">
            <td>
              <v-list-tile-avatar>
                <v-avatar size="38" color="primary">
                  <v-icon dark>contact_support</v-icon>
                </v-avatar>
              </v-list-tile-avatar>
            </td>
            <td>
              <span v-html="props.item.item.name" />
            </td>
            <td v-html="`${props.item.quantity} ${props.item.item.um}`" />
            <td v-if="user.role.id !== 7" class="text-xs-right">
              p/u: {{ props.item.item.priceCost | toPrice }} <br />
              Total:
              {{ (props.item.item.priceCost * props.item.quantity) | toPrice }}
              <br />
            </td>
            <td>-</td>
            <td>
              <v-chip v-if="props.item.web" color="success" text-color="white" label small>
                Activo
              </v-chip>
              <v-chip v-else color="error" text-color="white" label small>
                Inactivo
              </v-chip>
            </td>
            <td>
              <v-icon small @click="edit(props.item)">
                edit
              </v-icon>
              <v-icon small @click="deleteItemAction(props.item.id)">
                delete
              </v-icon>
            </td>
          </template>
          <template v-if="user.role.id !== 7" slot="footer">
            <tr>
              <td colspan="3" class="text-xs-right">
                <strong>Total</strong>
              </td>
              <td class="text-xs-right" colspan="1">
                {{ total | toPrice }}
              </td>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td colspan="3" class="text-xs-right">
                <strong>Utilidad</strong>
              </td>
              <td class="text-xs-right" colspan="1">
                <v-text-field v-model="utilidad" label="Porcentaje" hide-details class="mb-2"></v-text-field>
              </td>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td colspan="3" class="text-xs-right">
                <strong>Venta Aprox</strong>
              </td>
              <td class="text-xs-right" colspan="1">
                {{ (total + (total * utilidad) / 100) | toPrice }}
              </td>
              <td colspan="3"></td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-btn fixed dark fab bottom right color="accent" @click="edit">
      <v-icon> add </v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="300px">
      <v-card>
        <titleDialog :title="'Añadir Insumo'" close :close-action="() => (dialog = false)"> </titleDialog>
        <v-divider></v-divider>
        <v-card-text>
          <v-autocomplete
            v-model="insumo.item"
            :items="insumos"
            item-value="id"
            label="Insumos"
            placeholder="Buscar por codigo o nombre"
            return-object
            :item-text="item => `${item.codigo} / ${item.name}`"
            clearable
          >
            <template slot="item" slot-scope="{ item }">
              <v-list-tile-avatar v-if="item.photo !== ''">
                <v-img :src="item.photo" :lazy-src="item.photo" aspect-ratio="1" class="grey lighten-2"> </v-img>
              </v-list-tile-avatar>
              <v-list-tile-avatar v-else>
                <v-avatar size="38" color="primary">
                  <v-icon dark>contact_support</v-icon>
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="`${item.type} | ${item.name}`"></v-list-tile-title>
                <v-list-tile-sub-title v-html="item.codigo"></v-list-tile-sub-title>
              </v-list-tile-content>
            </template>
            <template slot="no-data">
              <div class="py-2 text-xs-center">No hay resultados</div>
            </template>
          </v-autocomplete>
          <v-text-field v-model="insumo.quantity" label="Cantidad" type="number"></v-text-field>
          <v-switch v-model="insumo.web" label="Mostrar en la web" hide-details> </v-switch>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="back" flat @click.native="dialog = false">Volver</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click.native="save">Listo</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { GET_INSUMOS, GET_PRODUCT } from '../../config'
import titleCard from '../useful/titleCard.vue'
import titleDialog from '../useful/titleDialog.vue'

export default {
  name: 'ProductComposicion',
  components: { titleCard, titleDialog },
  props: ['product', 'getProduct'],
  data: () => ({
    insumos: [],
    utilidad: 100,
    insumo: {
      id: 0,
      web: false,
      quantity: 0,
      item: null
    },
    rows: [],
    attributes: [],
    dialog: false,
    loadingBtn: false,
    loading: false,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {},
    states: [
      { name: 'Florida', abbr: 'FL', id: 1 },
      { name: 'Georgia', abbr: 'GA', id: 2 },
      { name: 'Nebraska', abbr: 'NE', id: 3 },
      { name: 'California', abbr: 'CA', id: 4 },
      { name: 'New York', abbr: 'NY', id: 5 }
    ]
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    total() {
      let total = 0
      this._.forEach(this.rows, i => {
        if (i.item) {
          total += i.item.priceCost * i.quantity
        }
      })
      return total
    }
  },
  watch: {
    product() {
      this.detail = this.product
      this.setReceta()
    }
  },
  mounted() {
    this.detail = this.product
    this.getInsumos()
  },
  methods: {
    async getInsumos() {
      try {
        const res = await this.$http.get(GET_INSUMOS)
        this.insumos = res.data
        this.setReceta()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async save() {
      const { insumo } = this
      try {
        await this.$http.post(`${GET_PRODUCT}/${this.product.id}/receta`, {
          insumo
        })
        this.getProduct()
        this.dialog = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: '¡Actualizado!'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteItem(id) {
      try {
        await this.$http.delete(`${GET_PRODUCT}/${this.product.id}/receta/${id}`)
        this.getProduct()
        this.$store.dispatch('changeSnack', {
          active: true,
          text: '¡Eliminado!'
        })
        this.$store.dispatch('setConfirm', {
          active: false
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    edit(item) {
      this.openDialog()
      this.insumo = {
        id: 0,
        web: false,
        quantity: 0,
        item: null
      }
      if (item) {
        this.insumo = item
      }
    },
    openDialog() {
      this.dialog = true
    },
    setReceta() {
      if (this._.size(this.detail) > 0) {
        const recetas = []
        this.detail.receta.forEach(e => {
          const result = this._.find(this.insumos, ['id', e.insumoID])
          if (result) {
            recetas.push({
              id: e.id,
              web: e.web,
              quantity: e.quantity,
              item: result
            })
          }
        })
        console.log(recetas)
        this.rows = recetas
      }
    },
    deleteItemAction(item) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar insumo del producto?',
        description: 'Se eliminara del sistema, no se puede recuperar',
        action: () => this.deleteItem(item)
      })
    }
  }
}
</script>
